<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active"
  >
    <!--begin:: Add customer-->
    <div class="row gutter-b">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Qr Code</h6>
          </template>
          <div class="d-flex flex-wrap">
            <div
              v-for="(sector, key) in branch.sectors"
              :key="key"
              :id="`imageId-${key}`"
            >
              <img
                :src="sector.qr_path"
                width="250px"
                height="250px"
                class="p-2"
              />
            </div>
          </div>
          <b-button @click="printButton()" variant="success"> Print </b-button>
        </b-card>
      </div>
    </div>
    <!--end:: Add customer-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "CustomerBranchQrCode",
  data() {
    return {
      // PROFILE Customer
      branch: {},
      location: {},
      place: null,
      description: "",
      addressMap: "",
      zoomMap: 15,
      radiusMap: 200,
      imageQr: "",
    };
  },
  components: {},
  mounted() {
    this.getData();
    let lastBreadcrumb = {};
    let branchTitle = [
      {
        title: "Site Group",
        route: "/customer",
      },
      {
        title: "Site",
        route: `/customer/branch/list/${this.$route.params.customerId}`,
      },
    ];
    if (!this.$route.params.customerId)
      branchTitle = [
        {
          title: "Site Group",
          route: `/customer/branch/list`,
        },
      ];
    lastBreadcrumb = { title: "QR Code" };
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      ...branchTitle,
      lastBreadcrumb,
    ]);
  },
  methods: {
    async getData() {
      try {
        const { data: getData } = await this.$axios.get(
          `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer-branch/${this.$route.params.id}`
        );
        this.branch = getData;
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    showModalQR(qrCode) {
      this.imageQr = qrCode;
      this.$refs["modal-qr-preview"].show();
    },
    openNewTab() {
      window.open(this.imageQr);
    },
    async printButton() {
      const customerBranchId = this.$route.params.id;
      await this.$axios
        .get(
          `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer-branch/generate-qr`,
          {
            params: {
              customer_branch_id: customerBranchId,
            },
          }
        )
        .then(({ data }) => {
          window.open(data.file);
        })
        .catch(({ response }) => {
          this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>
